<script>
import api from "@/helpers/api-rest/api.js";
import store from '@/state/store';
import format from 'date-fns/format';
import qs from 'qs';
import Swal from "sweetalert2";

export default {
  props: {
    id: { type: String, default: "ModalAccessionGeneral" },
    essayId: { type: [Number, String] },
    accession_general: {
      type: Object,
      default: () => {
        return {
          id: null,
          document_name: null,
          typeAccession: null,
          courses: '',
          students_number: null,
          accession_general_generate: {
            reason: '',
            accession_general_activities: []
          },
        };
      },
    },
  },
  data() {
    return {
      isModalOpen: false,
      dataUser: [],
      selectOptionsTrackingStatus: [],
      selectOptionsActions: [],
      collapseOpen: [],
      loading: false,
      essay: {}
    };
  },
  computed: {
    modalTitle() {
        return this.accession_general?.id ? "Editar Anexo V" : "Generar Anexo V";
    },
    submitButtonText() {
      return this.accession_general?.id ? 'Generar' : 'Generar';
    },
  },
  methods: {
    loadDataEssay(){
        const apiEndpoint = `/api/v1/essays/${this.essayId}`;
        api.get({url:apiEndpoint})
        .then(response => {
            this.essay = response?.data?.data;
        })
        .catch(error => {
            console.error("Error loading data:", error);
        });
    },
    showElement(roleId = [], show = true, showEdit = true) {
      const role = store.getters['auth/role'];
      if (roleId.includes(role.id) && show && showEdit) {
        return true;
      }
      return false;
    },
    onFileChange(event){
      this.file = event.target.files[0];
      this.accesion_center.document_name = this.file?.name;
      this.uploadFile(this.file, 'accession')
      .then((response) => {
        const file = response?.data?.data;
        this.accesion_center.document_path = file.path;
      })
      .catch((error) => {
        console.error("Error al cargar el archivo:", error);
      });
    },
    uploadFile(file, folder){
      const formData = new FormData();
      formData.set("file", file);
      formData.append("path", folder);
      const config = { headers: { "content-type": "multipart/form-data" } };

      const apiEndpoint = `/api/v1/upload-file`;
      return api.post({ url: apiEndpoint, data: formData, config })
    },
    toggleCollapse(index) {
        this.$set(this.collapseOpen, index, !this.collapseOpen[index]);
    },
    downloadFiles(fileName, folderID){
        const params = {
            'fileName': fileName,
            'folderID': folderID,
        };

        const queryString = qs.stringify(params);
        const baseUrl = process.env.VUE_APP_API_HOST;
        const url = `${baseUrl}/download-drive?${queryString}`;
        window.open(url, '_blank');
    },
    createAccessionGeneral() {
        console.log("Creado");
        this.loading = true;
        const apiEndpoint = `/api/v1/accession-general-generates`;
        this.accession_general.essay_id = this.essayId;
        api.post({url:apiEndpoint, data:this.accession_general})
        .then(response => {
            this.loading = false;
            const newAccessionGeneral = response?.data?.data;
            this.$emit('success', newAccessionGeneral);
            this.$bvModal.hide(this.id);
        })
        .catch(error => {
            this.loading = false;
            console.error("Error al crear adhesion de empresa:", error);
        });
    },
    updateAccessionGeneral() {
        this.loading = true;
        const apiEndpoint = `/api/v1/accession-general-generates`;
        api.put({url:apiEndpoint, data:this.accession_general, id:this.accession_general.id})
        .then(response => {
            this.loading = false;
            const updatedAccessionGeneral = response?.data?.data;
            this.$emit('success', updatedAccessionGeneral);
            this.$bvModal.hide(this.id);
            this.successmsg(updatedAccessionGeneral.url);
        })
        .catch(error => {
            this.loading = false;
            console.error("Error al editar adhesion de empresa:", error);
        });
    },
    changeStatusAccession(status){
      this.accesion_center.status = status;
      this.submitForm();
    },
    submitForm() {
      if(this.accession_general?.id){
          return this.updateAccessionGeneral();
      }
      return this.createAccessionGeneral();
    },
    cancelForm() {
        this.$bvModal.hide(this.id);
    },
    addRowActivity(){
        console.log("Añadir actividad");
        const currentDate = new Date().toISOString().slice(0, 10);
        this.accession_general.accession_general_generate.accession_general_activities.push({
            id: '',
            accession_general_id: this.accession_general.accession_general_generate.id,
            contingency_plan: '',
            cost: '',
            responsables: '',
            risks: '',
            init_date: currentDate,
            end_date: currentDate
        });
    },
    deleteRowActivity(key){
        this.accession_general?.accession_general_generate?.accession_general_activities.splice(key, 1);
    },
    formatDate(date) {
      return format(new Date(date), 'dd/MM/yyyy');
    },
    updateDate(event, index, field) {
        const value = event.target.value;
        this.accession_general.accession_general_generate.accession_general_activities[index][field] = value;
    },
    successmsg(url) {
        const swalWithBootstrapButtons = Swal.mixin({
            customClass: {
            confirmButton: "btn btn-success",
            },
            buttonsStyling: false
        });

        console.log(url);

        swalWithBootstrapButtons.fire({
            title: "!Buen trabajo!",
            html: `
            <div style="position: relative;">
                <p>Se ha generado el Anexo V correctamente<br><br></p>
                <iframe src="${url}" frameborder="0" style="height: 70vh; width: 100%;"></iframe>
            </div>
            `,
            icon: "success",
            width: 1000,
            padding: 20,
            allowOutsideClick: false,
            showCancelButton: false,
            confirmButtonText: "Aceptar",
        }).then((result) => {
            if (result.isConfirmed) {
                this.$swal.close();
            }
        });
    },
    loadData(){
        console.log(this.accession_general, this.id);
        this.loadDataEssay();
    },
  },
};
</script>
<template>
  <div>
    <b-modal :id="id" :title="modalTitle" @shown="loadData" scrollable size="xl">
      <template #default="{ }">
        <form @submit.prevent="submitForm" enctype="multipart/form-data">
            <div class="card">
                <div class="card-body">
                    <div class="row">
                        <div class="col-lg-12">
                            <h6>Título</h6>
                            <p class="text-muted">
                                {{ accession_general?.essay?.name }}
                            </p>
                        </div>
                        <div v-if="accession_general.typeAccession == 'center'" class="col-lg-12">
                            <h6>Ciclos formativos</h6>
                            <div class="form-group">
                                <textarea v-model="accession_general.accession_general_generate.courses" class="form-control" rows="3" placeholder="Introduce cursos"></textarea>
                            </div>
                        </div>
                        <div v-if="accession_general.typeAccession == 'center'" class="col-lg-12">
                            <h6>Número de alumnado</h6>
                            <div class="form-group">
                                <input v-model="accession_general.accession_general_generate.students_number" type="text" class="form-control" id="name" placeholder="Número de alumnado" required>
                            </div>
                        </div>
                        <div class="col-lg-12">
                            <h6>Justificación de la adhesión</h6>
                            <div class="form-group">
                                <textarea v-model="accession_general.accession_general_generate.reason" class="form-control" rows="3" placeholder="Introduce justificación de la adhesión"></textarea>
                            </div>
                        </div>
                        <div class="col-lg-12">
                            <h6>Aportación al proyecto</h6>
                            <div class="form-group">
                                <textarea v-model="accession_general.accession_general_generate.accession_input" class="form-control" rows="3" placeholder="Introduce aportación al proyecto"></textarea>
                            </div>
                        </div>
                        <div class="col-lg-12 mt-3">
                            <h6>Propuesta de actividades</h6>
                            <b-card no-body class="mb-1 shadow-none" v-for="(activity, i) in accession_general.accession_general_generate.accession_general_activities" :key="i">
                                <b-card-header header-tag="header" class="p-1" role="tab">
                                <h6 class="m-0 d-flex justify-content-between align-items-center">
                                    <div class="form-inline">
                                        <span>Actividad <small class="font-italic ml-3">({{ formatDate(activity.init_date) }} - {{ formatDate(activity.end_date) }})</small></span>
                                    </div>
                                    <div>
                                        <b-icon style="margin-right: 10px" :icon="collapseOpen[i] ? 'chevron-down' : 'chevron-up'" @click="toggleCollapse('collapse-' + i)"  v-b-toggle="'collapse-' + i"></b-icon>
                                        <a href="javascript:void(0);" class="text-danger" v-b-tooltip.hover title="Eliminar"
                                            @click="deleteRowActivity(i)">
                                            <i class="mdi mdi-trash-can font-size-18"></i>
                                        </a>
                                    </div>
                                </h6>
                                </b-card-header>
                                <b-collapse :id="'collapse-' + i" accordion="variation-accordion" role="tabpanel">
                                    <b-card-body>
                                        <b-card-text>
                                            <div class="row">
                                                <div class="col-lg-4">
                                                    <div class="form-group">
                                                        <label for="initDate">Ejecución inicial</label>
                                                        <input type="date" :value="formatDate(activity.init_date)" @input="updateDate($event, i, 'init_date')" id="initDate" class="form-control" placeholder="Ejecución inicial" />
                                                    </div>
                                                </div>
                                                <div class="col-lg-4">
                                                    <div class="form-group">
                                                        <label for="endDate">Ejecución final</label>
                                                        <input type="date" :value="formatDate(activity.end_date)" @input="updateDate($event, i, 'end_date')" id="endDate" class="form-control" placeholder="Ejecución final" />
                                                    </div>
                                                </div>
                                                <div class="col-lg-4">
                                                    <div class="form-group">
                                                        <label for="cost">Coste</label>
                                                        <input v-model="activity.cost" type="text" class="form-control" id="name" placeholder="Coste" required>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-lg-12">
                                                    <div class="form-group">
                                                        <label for="responsable">Responsable</label>
                                                        <input v-model="activity.responsables" type="text" class="form-control" id="name" placeholder="Responsable" required>
                                                    </div>
                                                </div>
                                                <div class="col-lg-12">
                                                    <div class="form-group">
                                                        <label for="risks">Riesgos</label>
                                                        <textarea v-model="activity.risks" class="form-control" id="risks" rows="3" placeholder="Introduce riesgos"></textarea>
                                                    </div>
                                                </div>
                                                <div class="col-lg-12">
                                                    <div class="form-group">
                                                        <label for="contingency_plan">Plan de contingencia</label>
                                                        <textarea v-model="activity.contingency_plan" class="form-control" id="contingency_plan" rows="3" placeholder="Introduce plan de contingencia"></textarea>
                                                    </div>
                                                </div>
                                            </div>
                                        </b-card-text>
                                    </b-card-body>
                                </b-collapse>
                            </b-card>
                            <a v-if="showElement([3], essay?.essay_status_id != 7)" href="javascript:void(0);" class="text-success" @click="addRowActivity()">
                                <i class="mdi mdi-plus-circle font-size-18"></i> Agregar nueva actividad
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </form>
      </template>
      <template #modal-footer="{  }">
        <b-button  v-if="showElement([3], essay?.essay_status_id != 7)" variant="success" @click="submitForm()" :disabled="loading"><span class="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true" v-if="loading"></span>{{ submitButtonText }}</b-button>
        <b-button @click="cancelForm()">Cancel</b-button>
      </template>
    </b-modal>
  </div>
</template>