<script>
import api from "@/helpers/api-rest/api.js";
import format from 'date-fns/format';
import store from '@/state/store';

export default {
  props: {
    id: { type: String, default: "ModalResumeInvoice" },
    resume: {
      type: Object,
      default: () => {
        return {
          id: null,
          essay_id: null,
          invoice_date: null
        };
      },
    },
    control_economic: {
      type: Object,
      default: () => {
        return {
          id: null,
        };
      },
    },
  },
  data() {
    return {
      isModalOpen: false,
      dataUser: [],
      selectOptionsTrackingStatus: [],
      selectOptionsActions: [],
      collapseOpen: [],
      loading: false,
      essay: {}
    };
  },
  computed: {
    modalTitle() {
        return this.resume?.id ? "Editar justificante" : "Añadir justificante";
    },
  },
  methods: {
    loadDataEssay(){
        const apiEndpoint = `/api/v1/essays/${this.resume.essay_id}`;
        api.get({url:apiEndpoint})
        .then(response => {
            this.essay = response?.data?.data;
        })
        .catch(error => {
            console.error("Error loading data:", error);
        });
    },
    showElement(roleId = [], show = true) {
      const role = store.getters['auth/role'];
      if (roleId.includes(role.id) && show) {
        return true;
      }
      return false;
    },
    createResume(){
      this.loading = true;
      const data = {...this.resume, tracking_control_economic_id: this.control_economic.id};
      const apiEndpoint = `/api/v1/resume-invoices`;
      api.post({url:apiEndpoint, data:data})
      .then(response => {
          this.loading = false;
          const newResume = response?.data?.data;
          this.$emit('success', newResume);
          this.$bvModal.hide(this.id);
      })
      .catch(error => {
          this.loading = false;
          console.error("Error al crear la acción:", error);
      });
    },
    updateResume() {
      this.loading = true;
      const apiEndpoint = `/api/v1/resume-invoices`;
      api.put({url:apiEndpoint, data:this.resume, id:this.resume.id})
      .then(response => {
          this.loading = false;
          const updatedResume = response?.data?.data;
          this.$emit('success', updatedResume);
          this.$bvModal.hide(this.id);
      })
      .catch(error => {
          this.loading = false;
          console.error("Error al editar la justificacion:", error);
      });
    },
    submitFormResume() {
      
      if(this.resume?.id){
          return this.updateResume();
      }
      return this.createResume();
    },
    loadData(){
      this.loadDataEssay();
      this.resume.invoice_date = format(new Date(this.resume?.invoice_date), 'yyyy-MM-dd');
      this.resume.date = format(new Date(this.resume?.date), 'yyyy-MM-dd');
      console.log(this.resume);
    },
    formatDate(date) {
      console.log(date);
      
      // return format(parseISO(date), 'yyyy-MM-dd');
    },
    cancelForm() {
        this.$bvModal.hide(this.id);
    },
    onFileChangeResume(event){
      this.file = event.target.files[0];
      this.resume.document_name = this.file?.name;
      this.uploadFile(this.file, 'resumes')
      .then((response) => {
        const file = response?.data?.data;
        this.resume.document_path = file.path;
      })
      .catch((error) => {
        console.error("Error al cargar el archivo:", error);
      });
    },
    uploadFile(file, folder){
      const formData = new FormData();
      formData.set("file", file);
      formData.append("path", folder);
      const config = { headers: { "content-type": "multipart/form-data" } };

      const apiEndpoint = `/api/v1/upload-file`;
      return api.post({ url: apiEndpoint, data: formData, config })
    },
  },
};
</script>
<template>
  
  <b-modal :id="id" :title="modalTitle" @shown="loadData" scrollable size="xl">
    <template #default="{ }">
      <form @submit.prevent="submitForm" enctype="multipart/form-data">
        <div class="card">
          <div class="card-body">
              <div class="row">
                <div class="col-lg-12">
                  <h4 class="card-title">Datos sobre la actuación</h4>
                  <h6 class="mt-3">Tipo de actuación</h6>
                  <div class="form-group">
                    <textarea v-model="resume.action_type" class="form-control" rows="3" placeholder=""></textarea>
                  </div>
                </div>
              </div>
              <div class="row mt-4">
                <div class="col-lg-12">
                  <h4 class="card-title">Datos sobre el perceptor</h4>
                </div>
                <div class="col-lg-6">
                  <div class="form-group">
                    <label for="receptor-identification">NIF / CIF</label>
                    <input type="text" id="receptor-identification" v-model="resume.dni" class="form-control"/>                                     
                  </div>
                </div>
                <div class="col-lg-6">
                  <div class="form-group">
                    <label for="receptor-name">Nombre o razón social</label>
                    <input type="text" id="receptor-name" v-model="resume.name" class="form-control" />                                     
                  </div>
                </div>
              </div>
              <div class="row mt-4">
                <div class="col-lg-12">
                  <h4 class="card-title">Datos sobre el gasto</h4>
                </div>
                <div class="col-lg-4">
                  <div class="form-group">
                    <label for="resume-description">Descripción del gasto</label>
                    <input type="text" id="resume-description" v-model="resume.material" class="form-control" />                                     
                  </div>
                </div>
                <div class="col-lg-4">
                  <div class="form-group">
                    <label for="resume-n_invoice">Número de factura o referencia</label>
                    <input type="text" id="resume-n_invoice" v-model="resume.n_invoice" class="form-control" />                                     
                  </div>
                </div>
                <div class="col-lg-4">
                  <div class="form-group">
                    <label for="resume-invoice_date">Fecha de factura</label>
                    <input type="date" id="resume-invoice_date" v-model="resume.invoice_date" class="form-control" />                                  
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-lg-4">
                  <div class="form-group">
                    <label for="resume-net_cost">Importe neto</label>
                    <input type="number" id="resume-net_cost" v-model="resume.net_cost" class="form-control" />                                     
                  </div>
                </div>
                <div class="col-lg-4">
                  <div class="form-group">
                    <label for="resume-iva_cost">Importe IVA</label>
                    <input type="number" id="resume-iva_cost" v-model="resume.iva_cost" class="form-control" />                                     
                  </div>
                </div>
                <div class="col-lg-4">
                  <div class="form-group">
                    <label for="resume-total_cost">Importe total</label>
                    <input type="number" id="resume-total_cost" v-model="resume.total_cost" class="form-control" />                                     
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-lg-4">
                  <div class="form-group">
                    <label for="resume-net_imputed_cost">Importe neto imputado</label>
                    <input type="number" id="resume-net_imputed_cost" v-model="resume.net_imputed_cost" class="form-control" />                                     
                  </div>
                </div>
                <div class="col-lg-4">
                  <div class="form-group">
                    <label for="resume-iva_imputed_cost">Importe IVA imputado</label>
                    <input type="number" id="resume-iva_imputed_cost" v-model="resume.iva_imputed_cost" class="form-control" />                                     
                  </div>
                </div>
                <div class="col-lg-4">
                  <div class="form-group">
                    <label for="resume-total_imputed_cost">Importe total imputado</label>
                    <input type="number" id="resume-total_imputed_cost" v-model="resume.total_imputed_cost" class="form-control" />                                     
                  </div>
                </div>
              </div>
              <div class="row mt-4" v-if="showElement([1])">
                <div class="col-lg-12">
                  <h4 class="card-title">Datos sobre el pago</h4>
                </div>
                <div class="col-lg-4">
                  <div class="form-group">
                    <label>Documento probatorio del pago</label>
                    <div class="custom-file">
                      <input id="inputFilePayment" type="file" name="filePayment" class="custom-file-input" @change="onFileChangeResume" />
                      <label class="custom-file-label" for="inputFilePayment">{{ resume?.document_name || 'Seleccione un archivo' }}</label>
                    </div>
                    <!-- <input type="text" id="resume-net_imputed_cost" v-model="resume.net_imputed_cost" class="form-control" />                                      -->
                  </div>
                </div>
                <div class="col-lg-4">
                  <div class="form-group">
                    <label for="resume-date">Fecha de pago</label>
                    <input type="date" id="resume-date" v-model="resume.date" class="form-control" />                                     
                  </div>
                </div>
                <div class="col-lg-4">
                  <div class="form-group">
                    <label for="resume-observations">Información adicional</label>
                    <input type="text" id="resume-observations" v-model="resume.observations" class="form-control" />                                     
                  </div>
                </div>
              </div>
              <!-- <div class="row mt-3">
                <div class="col-lg-12 mt-4">
                  <h6>Descripción</h6>
                  <p v-if="action?.id" class="text-muted">
                      {{ action?.descriptions }}
                  </p>
                  <div v-else class="form-group">
                      <textarea v-model="action.descriptions" class="form-control" id="descriptionAction" rows="3" placeholder="Descripción"></textarea>
                  </div>
                </div>
              </div>
              <div class="row mt-3">
                <div class="col-lg-12 mt-3">
                  <h6>Plan de contingencia</h6>
                  <p v-if="action?.id" class="text-muted">
                      {{ action?.contingency_plan }}
                  </p>
                  <div v-else class="form-group">
                      <textarea type="text" v-model="action.contingency_plan" id="actionPlan" class="form-control" rows="3" placeholder="Plan de contingencia"></textarea>
                  </div>
                </div>
              </div>
              <div class="row mt-3">
                <div class="col-lg-12 mt-3">
                  <h6>Riesgos</h6>
                  <p v-if="action?.id" class="text-muted">
                      {{ action?.risks }}
                  </p>
                  <div v-else class="form-group">
                      <textarea type="text" v-model="action.risks" id="actionRisks" class="form-control" rows="3" placeholder="Riesgos"></textarea>
                  </div>
                </div>
              </div> -->
          </div>
        </div>
      </form>
    </template>
    <template #modal-footer="{  }">
        <b-button v-if="!showElement([1,3], essay?.essay_status_id == 7)" variant="success" @click="submitFormResume()" :disabled="loading"><span class="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true" v-if="loading"></span>Guardar</b-button>
        <b-button @click="cancelForm()">Cancelar</b-button>
        <!-- <b-button @click="$emit('open-action-modal', 'reject', dataEssay?.id)" variant="danger" class="mx-5 w-25">Rechazar</b-button>
        <b-button @click="$emit('open-action-modal', 'return', dataEssay?.id)" variant="primary" class="mx-5 w-25">Devolución</b-button>
        <b-button @click="$emit('open-action-modal', 'approve', dataEssay?.id)" variant="success" class="mx-5 w-25">Aprobar</b-button> -->
    </template>
  </b-modal>
</template>