<script>
import api from "@/helpers/api-rest/api.js";
import store from '@/state/store';
import qs from 'qs';

export default {
  props: {
    id: { type: String, default: "ModalAccessionCompany" },
    essayId: { type: [Number, String] },
    centerId: { type: Number },
    accesion_company: {
      type: Object,
      default: () => {
        return {
          id: null,
          document_name: null
        };
      },
    },
  },
  data() {
    return {
      isModalOpen: false,
      dataUser: [],
      selectOptionsTrackingStatus: [],
      selectOptionsActions: [],
      collapseOpen: [],
      loading: false,
    };
  },
  computed: {
    modalTitle() {
        return this.accesion_company?.id ? "Editar adhesión" : "Añadir adhesión";
    },
    submitButtonText() {
      return this.accesion_company?.id ? 'Guardar' : 'Añadir';
    },
  },
  methods: {
    showElement(roleId = [], show = true, showEdit = true) {
      const role = store.getters['auth/role'];
      if (roleId.includes(role.id) && show && showEdit) {
        return true;
      }
      return false;
    },
    onFileChange(event){
      this.file = event.target.files[0];
      this.accesion_company.document_name = this.file?.name;
      this.uploadFile(this.file, 'accession')
      .then((response) => {
        const file = response?.data?.data;
        this.accesion_company.document_path = file.path;
      })
      .catch((error) => {
        console.error("Error al cargar el archivo:", error);
      });
    },
    uploadFile(file, folder){
      const formData = new FormData();
      formData.set("file", file);
      formData.append("path", folder);
      const config = { headers: { "content-type": "multipart/form-data" } };

      const apiEndpoint = `/api/v1/upload-file`;
      return api.post({ url: apiEndpoint, data: formData, config })
    },
    downloadFileAnexoVI(){
      const params = {
        'accesion_company': this.accesion_company,
        'essay_id': this.essayId,
        'center_id': this.centerId
      };
      const queryString = qs.stringify(params);
      const baseUrl = process.env.VUE_APP_API_HOST;
      const url = `${baseUrl}/anexo6?${queryString}`;      
      window.open(url, '_blank');
    },
    downloadFiles(fileName, folderID){
        const params = {
            'fileName': fileName,
            'folderID': folderID,
        };

        const queryString = qs.stringify(params);
        const baseUrl = process.env.VUE_APP_API_HOST;
        const url = `${baseUrl}/download-drive?${queryString}`;
        window.open(url, '_blank');
    },
    createAccessionCompany() {
      this.loading = true;
      const apiEndpoint = `/api/v1/accession-company-partners`;
      this.accesion_company.essay_id = this.essayId;
      api.post({url:apiEndpoint, data:this.accesion_company})
      .then(response => {
          this.loading = false;
          const newAccession = response?.data?.data;
          this.$emit('success', newAccession);
          this.$bvModal.hide(this.id);
      })
      .catch(error => {
          this.loading = false;
          console.error("Error al crear adhesion de empresa:", error);
      });
    },
    updateAccessionCompany() {
      this.loading = true;
      const apiEndpoint = `/api/v1/accession-company-partners`;
      api.put({url:apiEndpoint, data:this.accesion_company, id:this.accesion_company.id})
      .then(response => {
          this.loading = false;
          const updatedAccession = response?.data?.data;
          this.$emit('success', updatedAccession);
          this.$bvModal.hide(this.id);
      })
      .catch(error => {
          this.loading = false;
          console.error("Error al editar adhesion de empresa:", error);
      });
    },
    changeStatusAccession(status){
      this.accesion_company.status = status;
      this.submitForm();
    },
    submitForm() {
      if(this.accesion_company?.id){
          return this.updateAccessionCompany();
      }
      return this.createAccessionCompany();
    },
    cancelForm() {
        this.$bvModal.hide(this.id);
    },
    loadData(){
      console.log(this.essayId, typeof this.essayId);
      console.log(this.accesion_company);
    },
  },
};
</script>
<template>
  <div>
    <b-modal :id="id" :title="modalTitle" @shown="loadData" scrollable size="xl">
      <template #default="{ }">
        <form @submit.prevent="submitForm" enctype="multipart/form-data">
          <div class="card">
            <div class="card-body">
              <div class="row">
                <div class="col-lg-12">
                  <h6>Justificación</h6>
                  <p v-if="showElement([1])" class="text-muted">
                      {{ accesion_company?.reason }}
                  </p>
                  <div v-else class="form-group">
                    <textarea v-model="accesion_company.reason" class="form-control" rows="3" placeholder="Introduce justificación"></textarea>
                  </div>
                </div>
                <div class="col-lg-6">
                  <h6>Nombre</h6>
                  <p v-if="showElement([1])" class="text-muted">
                      {{ accesion_company?.name }}
                  </p>
                  <div v-else class="form-group">
                    <input v-model="accesion_company.name" type="text" class="form-control" id="name" placeholder="Nombre" required>
                  </div>
                </div>
                <div class="col-lg-6">
                    <h6>CIF</h6>
                    <p v-if="showElement([1])" class="text-muted">
                        {{ accesion_company?.cif }}
                    </p>
                    <div v-else class="form-group">
                        <input v-model="accesion_company.cif" type="text" class="form-control" id="cif" placeholder="CIF" required>
                    </div>
                </div>
                <div class="col-lg-12">
                    <h6>Dirección</h6>
                    <p v-if="showElement([1])" class="text-muted">
                        {{ accesion_company?.address }}
                    </p>
                    <div v-else class="form-group">
                        <textarea v-model="accesion_company.address" class="form-control" rows="3" placeholder="Dirección"></textarea>
                    </div>
                </div>
                <div class="col-lg-6">
                    <h6>Responsable</h6>
                    <p v-if="showElement([1])" class="text-muted">
                        {{ accesion_company?.responsable }}
                    </p>
                    <div v-else class="form-group">
                        <input v-model="accesion_company.responsable" type="text" class="form-control" id="responsable" placeholder="Responsable" required>
                    </div>
                </div>
                <div class="col-lg-6">
                    <h6>Email</h6>
                    <p v-if="showElement([1])" class="text-muted">
                        {{ accesion_company?.email }}
                    </p>
                    <div v-else class="form-group">
                        <input v-model="accesion_company.email" type="text" class="form-control" id="email" placeholder="Email" required>
                    </div>
                </div>
              </div>
              <div v-if="showElement([3])" class="row mt-4">
                <div class="col-lg-12">
                  <b-alert show variant="warning">
                    <strong>Observaciones</strong><br>
                    <ul>
                      <li>Antes de adjuntar el <strong>Anexo VI</strong>, primero debes generarlo <a href="javascript:void(0);" @click.prevent="downloadFileAnexoVI()">aquí</a></li>
                      <li>Si has completado los dos campos anteriores, también aparecerán en el <strong>Anexo VI</strong></li>
                    </ul>
                  </b-alert>
                </div>
                <div v-if="showElement([3], accesion_company?.essay?.essay_status_id != 7)" class="col-lg-6 mt-3">
                  <h6>Adjuntar Anexo VI</h6>
                  <div class="form-group">
                    <div class="custom-file">
                      <input id="inputFile" type="file" name="file" class="custom-file-input" @change="onFileChange" />
                      <label class="custom-file-label" for="inputFile">{{ accesion_company.document_name || 'Seleccione un archivo' }}</label>
                    </div>
                  </div>
                </div>
              </div>
              <div v-else class="row mt-3">
                <div class="col-lg-12 mt-3">
                  <h6>Anexo VI</h6>
                  <b-button variant="info" @click.prevent="downloadFiles(accesion_company?.document_name, accesion_company?.essay?.folder_google_id)"><i class="fas fa-file-download mr-2"></i> Descargar</b-button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </template>
      <template #modal-footer="{  }">
        <b-button  v-if="showElement([3], accesion_company?.essay?.essay_status_id != 7)" variant="success" @click="submitForm()" :disabled="loading"><span class="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true" v-if="loading"></span>{{ submitButtonText }}</b-button>
        <b-button  v-if="showElement([1])" variant="success" @click="changeStatusAccession('aceptada')" :disabled="loading"><span class="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true" v-if="loading"></span>Aceptar</b-button>
        <b-button  v-if="showElement([1])" variant="danger" @click="changeStatusAccession('rechazada')" :disabled="loading"><span class="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true" v-if="loading"></span>Rechazar</b-button>
        <b-button @click="cancelForm()">Cancel</b-button>
      </template>
    </b-modal>
  </div>
</template>