<script>
import api from "@/helpers/api-rest/api.js";
import store from '@/state/store';
import format from 'date-fns/format';
import PeriodicTrackingModal from '@/components/modals/tracking-modal.vue';

export default {
  props: ['essayId'],
  components: { 
    PeriodicTrackingModal,
  },
  data() {
    return {
      tableData: [],
      title: "Usuarios",
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      sortBy: "id",
      sortDesc: true,
      fields: [
        { key: "id", sortable: false, label: "#" },
        { key: 'created_by.name', sortable: false, label: 'Usuario' },
        { 
          key: 'created_at', sortable: false, label: 'Fecha de seguimiento', 
          formatter: (value) => {
            return format(new Date(value), 'dd/MM/yyyy');
          } 
        },
        // { key: "contingency", sortable: true, label: "Contingencia" },
        { key: "action", tdClass:"custom-table-action",  sortable: false, label: "" }
      ],
      editMode: false,
      periodicTrackingModalId: 'ModalPeriodicTracking',
      periodicTrackingId: undefined,
      essayIdTracking: undefined,
      dataEssay: {},
    };
  },
  computed: {
    /**
     * Total no. of records
     */
    rows() {
      return this.tableData.length;
    }
  },
  methods: {
    loadDataEssay(){
      const apiEndpoint = `/api/v1/essays/${this.essayId}`;
      api.get({url:apiEndpoint})
        .then(response => {
          const data = response?.data?.data;
          this.dataEssay = data;                   
        })
        .catch(error => {
          console.error("Error loading data:", error);
        });
    },
    showElement(roleId = [], show = true, showEdit = true) {
      const role = store.getters['auth/role'];
      if (roleId.includes(role.id) && show && showEdit) {
        return true;
      }
      return false;
    },
    loadDataFromApi(){
      const apiEndpoint = `/api/v1/periodic-trackings?essay_id=${this.essayId}`;
      api.get({url:apiEndpoint})
        .then(response => {
          this.tableData = response?.data?.data;
        })
        .catch(error => {
          console.error("Error loading data:", error);
        });
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    success(tracking) {
      console.log(tracking);
      this.loadDataFromApi();
    },
    admTracking(tracking = {}) {
      this.periodicTrackingId = tracking?.id || null;
      this.$bvModal.show(this.periodicTrackingModalId);
    },
  },
  mounted() {
    this.loadDataFromApi();
    this.loadDataEssay();
    // Set the initial number of items
    this.totalRows = this.tableData.length;
  },
}
</script>

<template>
  <div>
    <PeriodicTrackingModal :id="periodicTrackingModalId" :periodicTrackingId="periodicTrackingId" :essayId="essayId" @onClose="success"/>
    <button v-if="showElement([3], dataEssay?.essay_status_id != 7)" @click="admTracking()" class="btn btn-success mb-4"><i class="fa fa-plus"></i> Añadir seguimiento</button>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="row mt-2">
              <div class="col-sm-12 col-md-6">
                <h4 class="card-title">Lista de seguimientos</h4>
              </div>
              <!-- Search -->
              <div class="col-sm-12 col-md-6">
                <div id="tickets-table_filter" class="dataTables_filter text-md-right">
                  <label class="d-inline-flex align-items-center">
                    <b-form-input
                      v-model="filter"
                      type="search"
                      placeholder="Buscar..."
                      class="form-control form-control-sm ml-2"
                    ></b-form-input>
                  </label>
                </div>
              </div>
              <!-- End search -->
            </div>
            <!-- Table -->
            <div class="table-responsive mb-0">
              <b-table
                :items="tableData"
                :fields="fields"
                responsive="sm"
                :per-page="perPage"
                :current-page="currentPage"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :filter="filter"
                :filter-included-fields="filterOn"
                @filtered="onFiltered"
                show-empty
                ref="userTable"
              >
                <template v-slot:cell(tracking_status)="row">
                  <div>
                    <div>
                      <b-badge pill :variant="row.item.tracking_status.badge_color" class="ml-1">
                        {{ row.item.tracking_status.description }}
                      </b-badge>
                    </div>
                  </div>
                </template>
                <template v-slot:cell(action)="row">
                  <div>
                    <a href="javascript:void(0);" class="mr-3 text-secondary" title="Edit" @click="admTracking(row.item)">
                      <i class="mdi mdi-square-edit-outline font-size-18"></i>
                    </a>
                  </div>
                </template>
              </b-table>
            </div>
            <div class="row">
              <div class="col">
                <div class="dataTables_paginate paging_simple_numbers float-right">
                  <ul class="pagination pagination-rounded mb-0">
                    <!-- pagination -->
                    <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage"></b-pagination>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>